import { MEDIA_QUERIES } from '@student-coin/coinpaper-components';
import { css } from 'styled-components';
import tw, { styled } from 'twin.macro';

const typography = css`
  ${tw`font-inter`};
  font-size: 1rem;
  font-style: normal;
  letter-spacing: 0em;
  line-height: 0.813rem;

  @media ${MEDIA_QUERIES.tablet} {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
`;

export const Container = styled.div``;

export const Link = styled.a`
  ${typography};
  color: ${({ theme }) => theme.colors.label};
  font-weight: 600;
  z-index: 3;
  position: relative;
  &:hover {
    text-decoration: underline;
  }
`;

export const Separator = styled.span`
  ${typography};
  color: rgba(0, 0, 0, 0.3);
  font-weight: 400;
  @media ${MEDIA_QUERIES.tablet} {
    line-height: 1.063rem;
  }
`;
