import { Writer } from '@api/api';
import { Button } from '@components/common/Button';
import { getArticleDates } from '@utils/getArticleDates';
import { noop } from '@utils/noop';
import useTranslation from 'next-translate/useTranslation';
import { ReactElement } from 'react';
import { Author, Container, P } from './Footer.styles';
import { AuthorProps, FooterInsideProps, FooterProps } from './Footer.types';

const OneLineAuthorComponent = ({
  displayPublishedAt,
  updatedDate,
  publicationDate,
  author,
}: AuthorProps) => {
  return (
    <Author>
      <P variant="medium">
        {displayPublishedAt && <span>{updatedDate ?? publicationDate} </span>}
        {author && (
          <>
            by <span style={{ fontWeight: 'bold' }}>{(author as Writer).name}</span>
          </>
        )}
      </P>
    </Author>
  );
};

const NormalAuthor = ({
  displayPublishedAt,
  updatedDate,
  publicationDate,
  author,
}: AuthorProps) => {
  return (
    <Author>
      {displayPublishedAt && <P variant="small">{updatedDate ?? publicationDate}</P>}
      {author && (
        <P variant="small" isBold>
          {(author as Writer).name}
        </P>
      )}
    </Author>
  );
};

const Wrapper = ({ children, oneLineAuthor }) =>
  oneLineAuthor ? (
    <div className={`${oneLineAuthor ? 'center-author' : ''}`}>{children}</div>
  ) : (
    <>{children}</>
  );

const FooterInside = ({
  readTimeInMinutes,
  author,
  publicationDate,
  displayPublishedAt,
  updatedDate,
  oneLineAuthor,
  readTimeShow,
}: FooterInsideProps) => {
  const { t } = useTranslation('common');

  const readTimeText = readTimeShow ? `${readTimeInMinutes ?? 2}${t('min-read')}` : t('read');

  return (
    <Wrapper oneLineAuthor={oneLineAuthor}>
      <Button variant="read" text={readTimeText} onClick={noop} />

      {oneLineAuthor ? (
        <OneLineAuthorComponent
          author={author}
          publicationDate={publicationDate}
          displayPublishedAt={displayPublishedAt}
          updatedDate={updatedDate}
        />
      ) : (
        <NormalAuthor
          author={author}
          publicationDate={publicationDate}
          displayPublishedAt={displayPublishedAt}
          updatedDate={updatedDate}
        />
      )}
    </Wrapper>
  );
};

export const Footer = ({
  author,
  readTimeInMinutes,
  publishedDate,
  publishedAt,
  updatedAt = null,
  collection = false,
  displayPublishedAt = true,
  oneLineAuthor = false,
  readTimeShow = true,
}: FooterProps): ReactElement => {
  const { publishedAt: publicationDate, updatedAt: updatedDate } = getArticleDates({
    publishedAt,
    updatedAt,
    publishedDate,
    dateFormat: 'MMMM d, yyyy',
  });

  return (
    <Container className={`${collection ? 'container-collection' : ''}`}>
      <FooterInside
        readTimeInMinutes={readTimeInMinutes}
        author={author}
        publicationDate={publicationDate}
        displayPublishedAt={displayPublishedAt}
        updatedDate={updatedDate}
        oneLineAuthor={oneLineAuthor}
        readTimeShow={readTimeShow}
      />
    </Container>
  );
};
