import tw, { styled } from 'twin.macro';
import { MEDIA_QUERIES, Label as LabelBase } from '@student-coin/coinpaper-components';

export const Label = styled(LabelBase)`
  ${tw`font-inter`};
  font-size: 0.625rem;
  font-style: normal;
  letter-spacing: -0.03em;
  font-weight: 600;
  line-height: 0.813rem;
  color: ${({ theme }) => theme.colors.label};
  margin-top: 0px;
  cursor: pointer;
  text-transform: uppercase;
  align-items: center;

  @media ${MEDIA_QUERIES.tablet} {
    font-size: 0.875rem;
    line-height: 1.8rem;
  }
`;
