import React, { ReactElement } from 'react';
import { buildCategoryPageLink, buildSubcategoryPageLink } from '@utils/link';
import { Container, Link, Separator } from './Breadcrumb.styles';
import { BreadcrumbProps } from './Breadcrumb.types';
import { PageLink } from '../PageLink';

export const Breadcrumb = ({ category, subcategory, breadcrumbSize = '' }: BreadcrumbProps): ReactElement => {
  if (!category) {
    return null;
  }

  return (
    <Container>
      <PageLink href={buildCategoryPageLink(category.slug)}>
        <Link style={{ fontSize: breadcrumbSize }}>{category.name}</Link>
      </PageLink>
      {subcategory && (
        <>
          {' '}
          <Separator> &#47; </Separator>
          <PageLink href={buildSubcategoryPageLink(category.slug, subcategory.slug)}>
            <Link style={{ fontSize: breadcrumbSize }}>{subcategory.name}</Link>
          </PageLink>
        </>
      )}
    </Container>
  );
};
