import { H2, MEDIA_QUERIES, P } from '@student-coin/coinpaper-components';
import styled, { css } from 'styled-components';
import { Container as ContainerBase } from '../shared/Container/Container.styles';
import { ThumbnailPosition } from '../shared/Thumbnail/Thumbnail.types';
import { Variant } from '../shared/Variant.types';

const titleMedium = css`
  font-size: 1.125rem;
  line-height: 1.375rem;

  @media ${MEDIA_QUERIES.tablet} {
    font-size: 1.5rem;
    line-height: 1.813rem;
  }
`;

const titleSmall = css`
  font-size: 1rem;
  line-height: 1.188rem;

  @media ${MEDIA_QUERIES.tablet} {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
`;

export const Container = styled(ContainerBase)<{ variant: Variant; thumbnail: ThumbnailPosition }>`
  ${H2} {
    ${({ variant }) => {
      if (variant === 'large' || variant === 'medium') return titleMedium;
      return titleSmall;
    }};
  }
  position: relative;
`;

export const Description = styled(P)<{ limit?: number }>`
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-line-clamp: ${({ limit = 4 }) => limit};
  -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
  margin-top: 8px;
`;

export const StyledH2 = styled(H2)`
  position: relative;
  z-index: 3;
  &:hover {
    text-decoration: underline !important;
  }
`;
