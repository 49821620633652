import tw, { styled } from 'twin.macro';
import { MEDIA_QUERIES } from '@student-coin/coinpaper-components';

export const Header = styled.div`
  ${tw`flex flex-wrap justify-between`};
  margin-top: -0.5rem;

  @media ${MEDIA_QUERIES.tablet} {
    margin-top: -0.25rem;
  }
`;
