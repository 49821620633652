import { format, differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns';

export const getArticleDates = ({
  publishedDate,
  updatedAt = null,
  publishedAt,
  dateFormat,
}: {
  publishedDate: string;
  updatedAt?: string;
  publishedAt: string;
  dateFormat?: string;
}): { updatedAt: string | null; publishedAt: string } => {
  const date = new Date(publishedDate || publishedAt);
  const updatedAtDate = updatedAt && new Date(updatedAt);
  const differenceBetweenDates = differenceInSeconds(updatedAtDate, date);

  return {
    updatedAt:
      differenceBetweenDates > 0 ? `Edited ${formatDate(updatedAtDate, dateFormat)}` : null,
    publishedAt: formatDate(date, dateFormat),
  };
};

const formatDate = (date: Date, dateFormat = 'MMMM d, yyyy') => {
  const diffInMinutes = differenceInMinutes(new Date(), date);
  const diffInHours = differenceInHours(new Date(), date);
  const formattedDate = `${format(date, dateFormat)}`;

  if (diffInMinutes < 60 && diffInMinutes > 0) {
    return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
  }
  if (diffInHours <= 6 && diffInHours > 0) {
    return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
  }

  return formattedDate;
};
