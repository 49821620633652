import tw, { styled, TwStyle } from 'twin.macro';
import { MEDIA_QUERIES, H2, Link } from '@student-coin/coinpaper-components';
import { Content } from '../Content/Content.styles';
import { ThumbnailPosition } from '../Thumbnail/Thumbnail.types';
import { Variant } from '../Variant.types';

const StyledLink = styled(Link)``;

export const Container = styled.div<{ variant: Variant; thumbnail: ThumbnailPosition }>`
  ${tw`flex flex-col flex-1`};
  ${({ thumbnail }) => thumbnail === 'left' && tw`grid grid-cols-4`};

  ${Content} {
    ${({ thumbnail }) => thumbnail === 'left' && tw`col-span-3`};
    ${tw`flex flex-col justify-start`};
  }

  ${StyledLink} {
    text-decoration: none;
  }

  ${H2} {
    margin-top: 4px;
    margin-bottom: 8px;
  }

  @media ${MEDIA_QUERIES.tablet} {
    ${({ thumbnail, variant }): TwStyle => {
      if (thumbnail !== 'left') return null;
      if (variant === 'large') return tw`grid grid-cols-12`;
      if (variant === 'medium') return tw`grid grid-cols-8`;
      return tw`grid grid-cols-4`;
    }};

    ${H2} {
      margin-top: ${({ variant }) => (variant === 'large' ? '16px' : '8px')};
    }

    ${Content} {
      ${({ thumbnail, variant }): TwStyle => {
        if (thumbnail !== 'left') return null;
        if (variant === 'large') return tw`col-span-7`;
        if (variant === 'medium') return tw`col-span-5`;
        return tw`col-span-3`;
      }};
    }
  }
`;
