import { NextImage } from '@components/nextImage/NextImage';
import { MEDIA_QUERIES, useMediaQuery } from '@student-coin/coinpaper-components';
import { FunctionComponent } from 'react';
import { Container } from './Thumbnail.styles';
import { ThumbnailPosition, ThumbnailProps, ThumbnailSize } from './Thumbnail.types';

const THUMBNAIL_SIZE: Record<ThumbnailSize, { width: number; height: number }> = {
  roundish: {
    width: 133,
    height: 124,
  },
  extraTiny: {
    width: 40,
    height: 40,
  },
  tiny: {
    width: 100,
    height: 100,
  },
  small: {
    width: 314,
    height: 232,
  },
  medium: {
    width: 432,
    height: 154,
  },
  mediumX: {
    width: 432,
    height: 160,
  },
  mediumXs: {
    width: 432,
    height: 209,
  },
  mediumXx: {
    width: 432,
    height: 308,
  },
  large: {
    width: 550,
    height: 282,
  },
};

const getThumbnailSize = (
  size: ThumbnailSize,
  position: ThumbnailPosition,
  isTabletWide: boolean,
) => {
  if (position === 'left' && !isTabletWide && size !== 'roundish') {
    return {
      width: 80,
      height: 100,
    };
  }

  return THUMBNAIL_SIZE[size];
};

export const Thumbnail: FunctionComponent<ThumbnailProps> = ({
  position,
  variant,
  thumbnail,
  size = 'medium',
  layout = 'responsive',
}) => {
  const isTabletWide = useMediaQuery(MEDIA_QUERIES.tablet);

  return (
    <Container position={position} variant={variant}>
      <NextImage
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        src={thumbnail?.hash}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        title={thumbnail?.caption}
        alt={thumbnail?.alternativeText}
        layout={layout}
        {...getThumbnailSize(size, position, isTabletWide)}
        objectFit="cover"
        sizes="min-width(768px): 20vw"
      />
      {/* TODO: get rid of the optional chaining once we ensure article cannot be created without required data */}
    </Container>
  );
};
