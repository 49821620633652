import tw, { styled, TwStyle } from 'twin.macro';
import { MEDIA_QUERIES, Link as LinkBase } from '@student-coin/coinpaper-components';
import { Variant } from '../Variant.types';
import { ThumbnailPosition } from './Thumbnail.types';

export const Container = styled.div<{ variant: Variant; position: ThumbnailPosition }>`
  ${({ position }) => position === 'none' && tw`hidden`};
  ${({ position }) => position === 'left' && tw`col-span-1`};
  margin-bottom: ${({ position }) => (position === 'top' ? '1rem' : '0')};

  padding-right: ${({ position }): string => {
    if (position !== 'left') return '0';
    return '1rem';
  }};

  @media ${MEDIA_QUERIES.tablet} {
    ${({ position, variant }): TwStyle => {
      if (position !== 'left') return null;
      if (variant === 'large') return tw`col-span-5`;
      if (variant === 'medium') return tw`col-span-3`;
      return tw`col-span-1`;
    }};

    padding-right: ${({ position, variant }): string => {
      if (position !== 'left') return '0';
      if (variant === 'small') return '1.125rem';
      return '2.5rem';
    }};
  }
`;

export const Link = styled(LinkBase)`
  display: block;
`;
