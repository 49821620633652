import styled from 'styled-components';

export const StyledLink = styled.a`
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
  }
  &.contain-link-collection{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
