
import { imgLoader } from '@utils/imgLoader';
import OriginalNextImage, {ImageProps} from 'next/image';
import * as React from "react";


export const NextImage:React.FC<ImageProps> = ({loader,  ...other}) => {
    // HACK  next/image know nothing about css prop
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return  <OriginalNextImage {...other as any} loader={loader??imgLoader}/>;
}

NextImage.displayName = 'NextImage';