import { Sponsored } from '@components/article/Sponsored/Sponsored';
import dynamic from 'next/dynamic';
import { FunctionComponent } from 'react';
import { Breadcrumb } from '../../Breadcrumb';
import { ContainLink } from '../ContainLink/ContainLink';
import { Content, Header } from '../shared';
import { Thumbnail as ThumbnailType } from '../shared/Thumbnail/Thumbnail.types';
import { Container, Description, StyledH2 } from './ArticleCard.styles';
import { ArticleCardProps } from './ArticleCard.types';

const Thumbnail = dynamic(() =>
  import('../shared/Thumbnail/Thumbnail').then((mod) => mod.Thumbnail),
);
const Footer = dynamic(() =>
  import('../shared/Footer/Footer').then<typeof import('../shared/Footer/Footer').Footer>(
    (mod) => mod.Footer,
  ),
);

export const ArticleCard: FunctionComponent<ArticleCardProps> = ({
  href,
  article,
  thumbnail,
  variant,
  withDescription = true,
  descriptionLimit,
  size,
  displayPublishedAt = true,
  oneLineAuthor = false,
  noFooter = false,
  headingSize = '',
  breadcrumbSize = '',
}: ArticleCardProps) => {
  return (
    <Container variant={variant} thumbnail={thumbnail}>
      {article.thumbnail && (
        <Thumbnail
          variant={variant}
          position={thumbnail}
          thumbnail={article.thumbnail as ThumbnailType}
          size={size}
        />
      )}

      <Content>
        <Header>
          <Breadcrumb
            category={article.categories?.[0]}
            subcategory={article.subcategories?.[0] ?? null}
            breadcrumbSize={breadcrumbSize}
          />
          <Sponsored sponsored={article.sponsored} />
        </Header>

        <ContainLink href={href}>
          <StyledH2 as="h3" style={{ display: 'inline', fontSize: headingSize }}>
            {article.title}
          </StyledH2>
          {withDescription && (
            <div>
              <Description variant={variant} limit={descriptionLimit}>
                {article.description}
              </Description>
            </div>
          )}
        </ContainLink>

        {noFooter ? null : (
          <Footer
            author={article.author}
            publishedAt={article.publishedAt}
            publishedDate={article.publishedDate}
            readTimeInMinutes={article.readTimeInMinutes}
            displayPublishedAt={displayPublishedAt}
            oneLineAuthor={oneLineAuthor}
          />
        )}
      </Content>
    </Container>
  );
};
