import { CLOUDINARY_BASE_URL, CLOUDINARY_NAME } from "@consts/env";
import { ImageLoader } from "next/image";

export const getCloudinaryDownloadUrl = (hash: string): string =>
    `${CLOUDINARY_BASE_URL}/${CLOUDINARY_NAME}/${hash}`;

// custom proxy over
// see https://nextjs.org/docs/pages/api-reference/next-config-js/images#cloudinary
export const imgLoader: ImageLoader = ({ src, width, quality }) => {
    const props = [
        'f_webp',
        'c_limit',
        `w_${width}`,
        `q_${quality || 'auto:good'}`,
    ].join(',');
    return `${CLOUDINARY_BASE_URL}/${CLOUDINARY_NAME}/${props}/${src}`
}

export const svgLoader: ImageLoader = ({ src, width, quality }) => {
    const props = [
        `w_${width}`,
        `q_${quality || 'auto:good'}`,
    ].join(',');
    return `${CLOUDINARY_BASE_URL}/${CLOUDINARY_NAME}/${props}/${src}`
}
