import tw, { styled } from 'twin.macro';
import { MEDIA_QUERIES, P as PBase } from '@student-coin/coinpaper-components';

export const Author = styled.div`
  ${tw`grid justify-items-stretch`};
  color: ${({ theme }) => theme.colors.label};
`;

export const Container = styled.div`
  ${tw`flex flex-row-reverse justify-between items-end h-full`};
  margin-top: 16px;
  &.container-collection {
    height: auto;
    margin: 28px 0 0 0;

    @media ${MEDIA_QUERIES.tablet} {
      margin: 50px 0 0 0;
    }
  }

  .center-author {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

export const P = styled(PBase)<{ isBold?: boolean }>`
  color: ${({ theme }) => theme.colors.label};
  ${({ isBold }) => isBold && 'font-weight: 600'};
`;
