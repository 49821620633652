import { ReactElement } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { SponsoredProps } from './Sponsored.types';
import { Label } from './Sponsored.styles';

export const Sponsored = ({ sponsored }: SponsoredProps): ReactElement => {
  const { t } = useTranslation('common');

  return sponsored && <Label>{t('sponsored-article')}</Label>;
};
