import { FC } from 'react';
import NextLink from 'next/link';
import { PageLinkProps } from '@components/common/PageLink/PageLink.types';
import { StyledLink } from './ContainLink.styles';

export const ContainLink: FC<PageLinkProps> = ({href, children, collection = false }) => {
  return (
    <NextLink href={href} passHref>
      <StyledLink className={`${collection ? 'contain-link-collection' : ''}`}>{children}</StyledLink>
    </NextLink>
  );
};
